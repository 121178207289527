import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.career',
        path: `${APP_PREFIX_PATH}/dashboards/career`,
        component: React.lazy(() => import('views/app-views/dashboards/career')),
    },
    {
        key: 'dashboard.query',
        path: `${APP_PREFIX_PATH}/dashboards/query`,
        component: React.lazy(() => import('views/app-views/dashboards/query')),
    },
    {
        key: 'dashboard.solutions',
        path: `${APP_PREFIX_PATH}/dashboards/solution`,
        component: React.lazy(() => import('views/app-views/dashboards/solution')),
    },
    {
        key: 'dashboard.products',
        path: `${APP_PREFIX_PATH}/dashboards/product`,
        component: React.lazy(() => import('views/app-views/dashboards/product')),
    },
    {
        key: 'dashboard.industries',
        path: `${APP_PREFIX_PATH}/dashboards/industry`,
        component: React.lazy(() => import('views/app-views/dashboards/industry')),
    },
    {
        key: 'dashboard.news',
        path: `${APP_PREFIX_PATH}/dashboards/news`,
        component: React.lazy(() => import('views/app-views/dashboards/news')),
    },
    {
        key: 'dashboard.blogs',
        path: `${APP_PREFIX_PATH}/dashboards/blog`,
        component: React.lazy(() => import('views/app-views/dashboards/blog')),
    },
   ]